import Banner from '@/components/Banner';
import {getLocalizedStrings} from '@/localization/localization-store';

export default AboveLoginForm;

/**
 * Renders a the custom content to display above the Recharge login form.
 * This should be injected on the /tools/recurring/login page.
 */
function AboveLoginForm() {
  const {
    accountsMaintenanceBannerText: bannerText,
    accountsMaintenanceBannerLinkText: linkText,
    accountsMaintenanceBannerLinkUrl: linkUrl,
  } = getLocalizedStrings();
  return (
    <section className="-mx-4 -mb-16 flex flex-col justify-center gap-y-3 px-4 text-center">
      {(!!bannerText || !!linkText) && (
        <Banner
          text={bannerText}
          linkText={linkText}
          linkUrl={linkUrl}
        ></Banner>
      )}
    </section>
  );
}
