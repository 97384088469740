type BannerProps = {
  text?: string;
  className?: string;
  textClassName?: string;
  linkText?: string;
  linkUrl?: string;
};

/**
 * A banner component that displays a message.
 * @param text - The content to display in the banner.
 * @param className - The class name to apply to the banner.
 * @param textClassName - The class name to apply to the text in the banner.
 * @returns A banner component.
 */
const Banner = ({
  text,
  className = 'bg-deep-ocean-blue',
  textClassName = 'text-crisp-white',
  linkText,
  linkUrl,
}: BannerProps) => {
  return (
    <div
      className={`flex flex-col items-center justify-center gap-y-2 px-3 py-2 text-start md:p-4 md:px-6 ${className}`}
    >
      {text && (
        <span
          className={`whitespace-break-spaces leading-normal tracking-wide sm:text-sm md:font-medium lg:text-xl ${textClassName}`}
        >
          {text}
        </span>
      )}
      {linkText && (
        <a
          href={linkUrl}
          className="tracking-normal text-crisp-white underline hover:text-crisp-white"
        >
          {linkText}
        </a>
      )}
    </div>
  );
};

export default Banner;
