import AboveLoginForm from '@/components/AboveLoginForm';
import {logError, logInfo} from '@/utilities/log';
import {render} from 'preact';

mountAboveLoginFormComponent();

/**
 * Mounts the above-login-form component above the recharge login form.
 * This allows us to inject custom content above the login form.
 */
function mountAboveLoginFormComponent() {
  try {
    const rechargeLoginForm = document.querySelector('.rc-login');
    if (!rechargeLoginForm) {
      throw new Error('RechargeLoginFormNotFound');
    }

    const target = document.createElement('div');
    target.id = 'above-login-form';
    rechargeLoginForm?.insertAdjacentElement('beforebegin', target);
    render(<AboveLoginForm />, target);
    logInfo('MountedAboveLoginFormComponent');
  } catch (error) {
    logError(error);
    logError(new Error('FailedToMountAboveLoginFormComponent'));
  }
}
